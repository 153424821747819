import React from "react";
import ReactDOM from "react-dom/client";
import { Store } from "@ludens-reklame/rubics-v3-sdk/store/types";
import { Section } from "@ludens-reklame/rubics-v3-sdk/section/types";
import { App } from "./app";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

const ImageCarousel = React.lazy(
  () => import("./components/imageCarousel/imageCarousel")
);
const Hero = React.lazy(() => import("./components/hero/hero"));
const ImageGallery = React.lazy(
  () => import("./components/imageGallery/imageGallery")
);
const PresentationCard = React.lazy(
  () => import("./components/presentationCard/presentationCard")
);
const ImageAndText = React.lazy(
  () => import("./components/imageAndText/imageAndText")
);
const SelectedPages = React.lazy(
  () => import("./components/selectedPages/selectedPages")
);
const PagesSearch = React.lazy(
  () => import("./components/pagesSearch/pagesSearch")
);
const Paywall = React.lazy(() => import("./components/paywall/paywall"));
const CourseFilter = React.lazy(
  () => import("./components/courseFilter/courseFilter")
);
const Product = React.lazy(() => import("./components/product/product"));
const SearchResults = React.lazy(
  () => import("./components/searchResults/searchResults")
);
const Text = React.lazy(() => import("./components/text/text"));
const MembersCarousel = React.lazy(
  () => import("./components/membersCarousel/membersCarousel")
);
const PdfViewer = React.lazy(() => import("./components/pdfViewer/pdfViewer"));
const ImageVideo = React.lazy(
  () => import("./components/imageVideo/imageVideo")
);
const CtaBecomeMember = React.lazy(
  () => import("./components/ctaBecomeMember/ctaBecomeMember")
);
const ContactForm = React.lazy(
  () => import("./components/contactForm/contactForm")
);
const ExpandableSections = React.lazy(
  () => import("./components/expandableSections/expandableSections")
);
const Employees = React.lazy(() => import("./components/employees/employees"));
const CtaForm = React.lazy(() => import("./components/ctaForm/ctaForm"));
const CtaBoxy = React.lazy(() => import("./components/ctaBoxy/ctaBoxy"));
const MemberSignup = React.lazy(
  () => import("./components/memberSignup/memberSignup")
);
const VerticalMenu = React.lazy(
  () => import("./components/verticalMenu/verticalMenu")
);
const MyProfile = React.lazy(() => import("./components/myProfile/myProfile"));
const Cart = React.lazy(() => import("./components/cart/cart"));
const CtaMagazine = React.lazy(
  () => import("./components/ctaMagazine/ctaMagazine")
);
const Memberships = React.lazy(
  () => import("./components/memberships/memberships")
);
const ProductList = React.lazy(
  () => import("./components/publicationList/productList")
);
const Checkout = React.lazy(() => import("./components/checkout/checkout"));

declare var _STORE: Store;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App store={_STORE} renderer={renderSection} />
  </React.StrictMode>
);

function renderSection(
  section: Section,
  children?: React.ReactNode
): React.ReactNode {
  let suspend: boolean = true;
  let Component: React.ComponentType<any>;
  const domElem = document.getElementById(section.name);

  switch (section.component) {
    case "imageCarousel":
      Component = ImageCarousel;
      break;
    case "imageGallery":
      Component = ImageGallery;
      break;
    case "presentationCard":
      Component = PresentationCard;
      break;
    case "imageAndText":
      Component = ImageAndText;
      break;
    case "hero":
      Component = Hero;
      break;
    case "selectedPages":
      Component = SelectedPages;
      break;
    case "pagesSearch":
      Component = PagesSearch;
      break;
    case "paywall":
      Component = Paywall;
      break;
    case "header":
      Component = Header;
      suspend = false;
      break;
    case "Footer":
      Component = Footer;
      suspend = false;
      break;
    case "courseFilter":
      Component = CourseFilter;
      break;
    case "product":
      Component = Product;
      break;
    case "searchResults":
      Component = SearchResults;
      break;
    case "text":
      Component = Text;
      break;
    case "membersCarousel":
      Component = MembersCarousel;
      break;
    case "pdfViewer":
      Component = PdfViewer;
      break;
    case "imageVideo":
      Component = ImageVideo;
      break;
    case "ctaBecomeMember":
      Component = CtaBecomeMember;
      break;
    case "contactForm":
      Component = ContactForm;
      break;
    case "expandableSections":
      Component = ExpandableSections;
      break;
    case "employees":
      Component = Employees;
      break;
    case "ctaForm":
      Component = CtaForm;
      break;
    case "ctaBoxy":
      Component = CtaBoxy;
      break;
    case "memberSignup":
      Component = MemberSignup;
      break;
    case "verticalMenu":
      Component = VerticalMenu;
      break;
    case "myProfile":
      Component = MyProfile;
      break;
    case "cart":
      Component = Cart;
      break;
    case "ctaMagazine":
      Component = CtaMagazine;
      break;
    case "memberships":
      Component = Memberships;
      break;
    case "productList":
      Component = ProductList;
      break;
    case "checkout":
      Component = Checkout;
      break;
    default:
      return null;
  }

  if (!suspend) {
    return <Component {...section.props}>{children}</Component>;
  }

  return (
    <React.Suspense
      fallback={
        domElem ? (
          <div dangerouslySetInnerHTML={{ __html: domElem.innerHTML }} />
        ) : null
      }
    >
      {/* @ts-ignore */}
      <Component {...section.props}>{children}</Component>
    </React.Suspense>
  );
}
