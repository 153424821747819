import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { ThemeSettings } from "../../types/theme.js";
import { getLinkProps } from "../../client-utils/getLinkProps.js";
import { RichText } from "../../internal-components/richText/RichText.js";

interface Props {}

const Footer: React.FC<Props> = () => {
  const store = useStore<ThemeSettings>();
  const themeSettings = store.themeSettings;
  const logo = themeSettings.footerLogo;
  const menus = themeSettings.footerMenus || [];
  const socials = themeSettings.footerSocials || [];
  const text = themeSettings.footerText;

  return (
    <footer className="footer">
      <div className="mw hs">
        <div className="top" />
        <div className="menus">
          {logo && (
            <ul className="menu">
              <li>
                <img
                  src={transformImageUrl(logo, { height: 102 })}
                  alt={logo.altText}
                  loading="lazy"
                />
              </li>
            </ul>
          )}
          {menus.map((menu, index) => {
            return (
              <ul key={index} className="menu">
                {(menu.items || []).map((item, index) => {
                  if (item.label) return <li key={index}>{item.label}</li>;

                  if (item.link) {
                    return (
                      <li key={index} className="item">
                        <a className="b2" {...getLinkProps(item.link)}>
                          {item.link.title}
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            );
          })}
        </div>
        <div className="bottom">
          {text && <RichText html={text} />}
          {socials.length > 0 && (
            <ul className="socials">
              {socials.map((social, index) => {
                if (!social.icon) return null;

                return (
                  <li key={index}>
                    <a
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social"
                      aria-label={`Gå til ${social.url}`}
                    >
                      <img
                        src={transformImageUrl(social.icon, { height: 24 })}
                        alt={social.icon?.altText}
                        loading="lazy"
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
