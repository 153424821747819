import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { AnchorHTMLAttributes } from "react";

export function getLinkProps(
  link: Link
): AnchorHTMLAttributes<HTMLAnchorElement> {
  return {
    href: link.url,
    target: link.external ? "_blank" : undefined,
    rel: link.external ? "noopener noreferrer" : undefined,
  };
}
