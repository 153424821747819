import { ModalProvider } from "@ludens-reklame/react-modal";
import { ComponentRenderer, Page } from "@ludens-reklame/rubics-v3-react/page";
import { Store } from "@ludens-reklame/rubics-v3-sdk/store/types";

export const App: React.FC<{ store: Store; renderer: ComponentRenderer }> = ({
  store,
  renderer,
}) => {
  return (
    <ModalProvider>
      <Page store={store} useCartContext renderComponent={renderer}>
        {({ header, main, footer }) => {
          return (
            <>
              {header}
              <main>{main}</main>
              {footer}
            </>
          );
        }}
      </Page>
    </ModalProvider>
  );
};
