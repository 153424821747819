import { useCallback, useState } from "react";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { ThemeSettings } from "../../types/theme.js";
import { getLinkProps } from "../../client-utils/getLinkProps.js";
import { cart, close, hamburger, search } from "../../icons/svg.js";

interface Props {}

const Header: React.FC<Props> = () => {
  const store = useStore<ThemeSettings>();
  const logo = store.site.logo;
  const query = store.context.query;
  const themeSettings = store.themeSettings;
  const shortlinks = themeSettings?.headerShortlinks || [];
  const menus = themeSettings?.headerMenus || [];
  const cartUrl = themeSettings?.headerCartUrl;

  const [isMainMenuOpen, setIsMainMenuOpen] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>(
    (query.q as string) || ""
  );

  const handleActionClickState = useCallback(
    (type: "menu" | "search") => {
      if (type === "menu") {
        setIsMainMenuOpen(!isMainMenuOpen);

        if (isSearchOpen) {
          setIsSearchOpen(false);
        }
      } else {
        setIsSearchOpen(!isSearchOpen);
        if (isMainMenuOpen) {
          setIsMainMenuOpen(false);
        }
      }
    },
    [isSearchOpen, isMainMenuOpen, setIsMainMenuOpen, setIsSearchOpen]
  );

  return (
    <header className="header">
      <div className="hs mw content">
        <div>
          {logo && (
            <a href="/" aria-label="Gå til forsiden">
              <img
                src={transformImageUrl(logo, { height: 64, fit: "contain" })}
                alt="Logo"
                height={32}
                className="logo"
              />
            </a>
          )}
        </div>
        <div className="menus">
          {shortlinks.length > 0 && (
            <nav className="shortlinks">
              <ul>
                {shortlinks.map((shortLink, index) => {
                  if (!shortLink.link) return null;
                  const linkProps = getLinkProps(shortLink.link);

                  return (
                    <li key={index}>
                      <a {...linkProps} className="b3">
                        {shortLink.link.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
          )}
          <nav className="actions">
            <ul>
              <li>
                <button
                  className="b3"
                  id="search-form-trigger"
                  aria-controls="search-form"
                  aria-label="Åpne søkedialog"
                  aria-expanded={isSearchOpen}
                  onClick={() => handleActionClickState("search")}
                >
                  {search}
                </button>
              </li>
              {cartUrl && (
                <li>
                  <a href={cartUrl} className="b3">
                    {cart}
                  </a>
                </li>
              )}

              <li>
                <button
                  id="main-menu-trigger"
                  className="b3"
                  aria-controls="main-menu"
                  aria-label="Åpne hovedmeny"
                  aria-expanded={isMainMenuOpen}
                  onClick={() => handleActionClickState("menu")}
                >
                  {isMainMenuOpen ? close : hamburger}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {isSearchOpen && (
        <form
          id="search-form"
          className="search-form"
          action="/sokeresultater"
          method="GET"
        >
          <div className="hs mw mw-small">
            <input
              type="search"
              className="b1"
              placeholder="Søk i hele nettsiden..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              autoFocus
              name="q"
            />
            <input type="submit" hidden />
          </div>
        </form>
      )}
      {isMainMenuOpen && (
        <div id="main-menu" className="main-menu">
          <div className="hs mw">
            <nav className="menu-columns">
              {shortlinks.length > 0 && (
                <ul className="shortlinks-mobile">
                  {shortlinks.map((shortLink, index) => {
                    if (!shortLink.link) return null;
                    const linkProps = getLinkProps(shortLink.link);

                    return (
                      <li key={index}>
                        <a {...linkProps} className="b2">
                          {shortLink.link.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
              {menus.map((menu, index) => {
                if (!menu.items) return null;

                return (
                  <ul key={index} className="menu-column">
                    {(menu.items || []).map((menuItem, index) => {
                      if (!menuItem.link) return null;
                      const linkProps = getLinkProps(menuItem.link);

                      return (
                        <li key={index}>
                          <a {...linkProps} className="b1">
                            {menuItem.link.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
