export const RichText: React.FC<{ html: string; className?: string }> = ({
  html,
  className,
}) => {
  return (
    <div
      className={`rich-text${className ? ` ${className}` : ""}`}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
